.login {
  max-width: 420px;
  margin: auto;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
  padding: 1.5rem;
  border-radius: 0.5rem;
}
.login img {
  margin: auto;
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.login h2 {
  padding-bottom: 1rem;
  text-align: center;
}
.login-center {
  height: 100vh;
  display: flex;
  align-items: center;
}
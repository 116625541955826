.layout {
  color: var(--txt-color);
}
.layout a {
  color: var(--txt-color);
}

.layout__content {
  padding-left: var(--sidebar-width);
  background-color: var(--second-bg);
  min-height: 100vh;
}

.layout__content-main {
  padding: 1rem 1.5rem;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1120px;
}

.mpv-labal {
  text-align: right;
  padding-top: 8px;
  font-weight: 600;
}

.mpv-labal-left {
  text-align: left;
  padding-top: 8px;
  font-weight: 600;
  padding-bottom: 4px;
}

@media (max-width: 768px) {
  .mpv-labal {
    padding-bottom: 4px;
    text-align: left;
  }
  .mpv .ant-card-body {
    padding: 16px 0px !important;
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.mpv__title {
  font-size: 18px;
  line-height: 20px;
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  padding: 0.75rem 1rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.mpv .ant-card-head {
  background-color: #1890ff !important;
  color: #fff;
}
.mpv {
  border: 1px solid #1890ff !important;
  margin: 1rem 0rem !important;
}

.mpv-administrado {
  border: 1px solid #bce8f1 !important;
}
.mpv-administrado .ant-card-head {
  background-color: #d9edf7 !important;
  color: #31708f;
}

.mpv-file {
  border: 1px solid #bce8f1 !important;
}
.mpv-file .ant-card-head {
  background-color: #d9edf7 !important;
  color: #31708f;
}

@media (max-width: 768px) {
  .mpv-administrado {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mpv-file {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .m-pl-16 {
    padding-left: 1rem;
  }
}